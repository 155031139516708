import { Skeleton } from "@/Components/ui/skeleton";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";

export default function Loader({ className, text = "", iconHeight = 80, iconWidth = 80 }) {
    return <Skeleton className={cn("h-96 text-muted bg-muted-foreground colspan-1 flex flex-col gap-2 justify-center items-center", className)}>
        <Icon icon="svg-spinners:blocks-shuffle-3" width={iconWidth} height={iconHeight} />
        {!!text && <p className="mt-2">{text}</p>}
    </Skeleton>
}
